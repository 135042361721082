<template>
    <div class="main-dialogs-container">
        <div class="initial-modal">
            <el-dialog title="Make Payment" :visible.sync="dialogVisibleData" width="30%">
                <el-form :model="formData" :rules="rules"  label-position="left" @submit.prevent.native="checkEnter" ref="makePaymentForm">
                    <el-form-item label="Amount to pay" prop="amount_to_pay">
                        <el-input v-model="formData.amount_to_pay" placeholder="Enter amount"></el-input>
                    </el-form-item>
                    <el-form-item label="Payment Method" prop="payment_method" style="display:flex;flex-direction:column;">
                        <el-select v-model="formData.payment_method" style=" width: 100%;" filterable placeholder="Select payment method">
                            <el-option v-for="(item, index) in chartedList" :key="index" :value="item.name"
                                :label="item.name"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Notes" prop="notes">
                        <el-input v-model="formData.notes" placeholder="Add a note"></el-input>
                    </el-form-item>
                    <el-button type="primary" style="width:50%; margin:0 auto; display: flex; justify-content: center;" @click="checkEnter">Submit</el-button>
                </el-form>
            </el-dialog>
        </div>
        <div class="confirmation-modal">
            <el-dialog :visible.sync="confirmationModal" :title="'Confirmation'" v-if="confirmationModal" width="40%">
                <div class="heading">
                    <p>Are you sure you want to make payment?</p>
                </div>
                <div class="preview">
                    <p style="font-size:11px">Amount to pay: &nbsp; <strong style="font-size:14px">{{ formData.amount_to_pay }}</strong> </p>
                    <p style="font-size:11px">Payment Method: &nbsp; <strong style="font-size:14px">{{ formData.payment_method }}</strong>
                    </p>
                    <p style="font-size:11px">Note: &nbsp; <strong style="font-size:14px">{{ formData.notes }}</strong></p>
                    <p style="font-size:11px">User ID: &nbsp; <strong style="font-size:14px">{{ tableData.row.user_id }}</strong></p>
                    <p style="font-size:11px">Debtor ID: &nbsp; <strong style="font-size:14px">{{ tableData.row.id}}</strong></p>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="confirmationModal = false">Cancel</el-button>
                    <el-button type="danger" @click="submitNewData" :loading="buttonLoading">Confirm</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import store from '@/state/store.js'
export default {
    data() {
        return {
            confirmationModal: false,
            buttonLoading: false,
            chartedList: [],
            formData: {
                amount_to_pay: '',
                payment_method: '',
                notes: '',
            },
            rules: {
                amount_to_pay: [
                    {
                        required: true,
                        message: "Amount to pay is required.",
                        trigger: "blur",
                    },
                ],
                payment_method: [
                    { required: true, message: "Payment method is required", trigger: "blur" },
                ],
                notes: [
                    { required: true, message: "Notes is required", trigger: "blur" },
                ],
            }
        }
    },
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        },
        payload:{
            type: Object,
            default: () => {}
        }
    },
    methods: {
        checkEnter() {
            this.$refs['makePaymentForm'].validate((valid) => {
                if (valid) {
                    this.confirmationModal = true
                } else {
                    return false
                }
            })
        },
        submitNewData(){
            let payload = {
                paid_amount: this.formData.amount_to_pay,
                payment_method: this.formData.payment_method,
                notes: this.formData.notes,
                user_id: this.payload.row.user_id,
                debtor_id:this.payload.row.id,
            }
            this.buttonLoading = true
            store.dispatch('admin/reporting/clearDebitors', payload).then(res => {
                this.buttonLoading = false
                this.confirmationModal = false
                this.dialogVisibleData = false
                this.$emit('refresh')
            })
        }
    },
    computed: {
        dialogVisibleData: {
            get() {
                return this.dialogVisible
            },
            set(val) {
                this.$emit('update:dialogVisible', val)
            }
        },
        tableData: {
            get() {
                return this.payload==undefined?{}:this.payload
            },
            set(val) {
                this.$emit('update:payload', val)
            }
        }
    },
    mounted() {
        store.dispatch('admin/reporting/getChartAccounts').then(res => {
            this.chartedList = res
        })
    },
}
</script>
<style>

</style>