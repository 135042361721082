<template>
    <div class="row">
        <div class="col-md-12" v-if="loading">
            <div class="row mt-5 pt-5">
                <div class="col-md-12" v-loading="loading">

                </div>
            </div>
        </div>
        <div class="col-md-12 my-4" v-if="!loading">
            <div class="d-flex justify-content-end">
                <div class="form_contain">
                    <el-input placeholder="search name" class="search_item" style="height: 40px!important;"
                        v-model="search">
                        <i slot="prefix" style="padding-to:2em;" class="el-input__icon el-icon-search"></i>
                    </el-input>
                  
                </div>
            </div>
        </div>
        <div class="col-md-12" v-if="!loading">
            <el-table :data="displayData" :header-cell-style="{ background: 'rgba(212, 216, 226, 0.2)' }"
                style="width: 100%" :empty-text="'No Data Available'" height="55vh">
                <el-table-column label="Name">
                    <template slot-scope="scope">
                        {{`${scope.row.user.firstname} ${scope.row.user.lastname}`}}
                    </template>
                </el-table-column>
                <el-table-column label="Phone">
                    <template slot-scope="scope">
                        {{ scope.row.user.phone }}
                    </template>
                </el-table-column>
                <el-table-column label="Invoice Number">
                    <template slot-scope="scope">
                        {{ scope.row.invoice_no }}
                    </template>
                </el-table-column>
                <el-table-column label="Payable Amount">
                    <template slot-scope="scope">
                        {{ scope.row.payable_amount }}
                    </template>
                </el-table-column>
                <el-table-column label="Paid Amount">
                    <template slot-scope="scope">
                        {{ scope.row.paid_amount }}
                    </template>
                </el-table-column>
                <el-table-column label="Due Amount">
                    <template slot-scope="scope">
                        {{ scope.row.due }}
                    </template>
                </el-table-column>
                <el-table-column label="Status">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status==0" class="due">Due</span>
                        <span v-else-if="scope.row.status ==1" class="completed">Completed</span>
                    </template>
                </el-table-column>
                <el-table-column label="Created At">
                    <template slot-scope="scope">
                        {{ scope.row.date }}
                    </template>
                </el-table-column>
                <el-table-column label="Action">
                    <template slot-scope="scope">
                        <div class="buttons-container">
                            <button class="view-payment" title="View Individual Details" @click="openNewLink({ row: scope.row, key: scope.$index, })"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M19.4354 0.581983C18.9352 0.0685981 18.1949 -0.122663 17.5046 0.0786645L1.408 4.75952C0.679698 4.96186 0.163487 5.54269 0.0244302 6.28055C-0.117628 7.0315 0.378575 7.98479 1.02684 8.38342L6.0599 11.4768C6.57611 11.7939 7.24238 11.7144 7.66956 11.2835L13.4329 5.4843C13.723 5.18231 14.2032 5.18231 14.4934 5.4843C14.7835 5.77623 14.7835 6.24935 14.4934 6.55134L8.71999 12.3516C8.29181 12.7814 8.21178 13.4508 8.52691 13.9702L11.6022 19.0538C11.9623 19.6577 12.5826 20 13.2628 20C13.3429 20 13.4329 20 13.513 19.9899C14.2933 19.8893 14.9135 19.3558 15.1436 18.6008L19.9156 2.52479C20.1257 1.84028 19.9356 1.09537 19.4354 0.581983Z"
                                    fill="#D4D8E2" class="send-icon" />
                            </svg></button>
                            <button class="make-payment" title="Make Payment" @click="openPaymentModal({ row: scope.row, key: scope.$index, type: 'payment' })"><makePaymentIcon class="payment-icon"></makePaymentIcon></button>
                            <button class="delete-icon" title="Delete Entry" @click="openPaymentModal({ row: scope.row, key: scope.$index, type: 'delete' })"><svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                            >
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M17.4185 3.30531C17.8087 3.30531 18.1335 3.62537 18.1335 4.03263V4.40918C18.1335 4.80653 17.8087 5.1365 17.4185 5.1365H0.796052C0.4049 5.1365 0.0800781 4.80653 0.0800781 4.40918V4.03263C0.0800781 3.62537 0.4049 3.30531 0.796052 3.30531H3.72042C4.31447 3.30531 4.83145 2.88813 4.96509 2.29954L5.11823 1.62374C5.35624 0.703186 6.13952 0.0917969 7.03595 0.0917969H11.1777C12.0643 0.0917969 12.8564 0.703186 13.0856 1.57518L13.2495 2.29855C13.3822 2.88813 13.8992 3.30531 14.4942 3.30531H17.4185ZM15.9329 17.0702C16.2382 14.259 16.7727 7.58027 16.7727 7.51289C16.7922 7.30877 16.7249 7.11554 16.5913 6.95997C16.4479 6.8143 16.2665 6.7281 16.0665 6.7281H2.15474C1.9538 6.7281 1.76262 6.8143 1.62996 6.95997C1.49535 7.11554 1.42902 7.30877 1.43877 7.51289C1.44056 7.52527 1.45974 7.76053 1.49181 8.15384C1.63426 9.90107 2.03102 14.7674 2.2874 17.0702C2.46884 18.7666 3.59547 19.8328 5.22738 19.8715C6.48668 19.9002 7.78402 19.9101 9.11062 19.9101C10.3602 19.9101 11.6292 19.9002 12.9275 19.8715C14.616 19.8427 15.7417 18.7953 15.9329 17.0702Z"
                                    fill="#D4D8E2" class="delete-icon" />
                            </svg></button>
                        </div>
                    </template>
                </el-table-column>
                <!-- <el-table-column
                label="Action"
                width="200">
                <template slot-scope="scope">
                    <button id="enter__account" class="approve" @click="publish(scope)" >
                        Publish
                    </button>
                </template>
            </el-table-column> -->
            </el-table>
        </div>
        <div class="col-md-12" style="text-align: center; margin-top:2em;">
            <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
                :page-size="pageSize" :total="total">
            </el-pagination>
        </div>
        <makePayment :dialogVisible="dialogVisible" @update:dialogVisible="dialogVisible = false" :payload="dataForModal"
            @refresh="refetchData"></makePayment>
        <DeleteMainDebt :payload="dataForModal" :deleteVisible="deleteVisible"  @update:deleteVisible="deleteVisible = false"></DeleteMainDebt>
    </div>
</template>
<script>
import store from '@/state/store.js'
import appConfig from '@/app.config'
import makePayment from './makePayment.vue'
import DeleteMainDebt from './DeleteMainDebt.vue'
import makePaymentIcon from './makePaymentIcon.vue'
export default {
    page: {
        title: 'Debtors list',
        meta: [{ name: 'description', content: appConfig.description }],
    },
    components: {
        makePayment,
        DeleteMainDebt,
        makePaymentIcon
    },
    data() {
        return {
            loading: false,
            tableData: [],
            page: 1,
            pageSize: 20,
            total: 0,
            search: '',
            dialogVisible: false,
            deleteVisible: false,
            dataForModal: {},
            status:"",
        }
    },
    methods: {
        handleCurrentChange(val) {
            this.page = val;
        },
        openPaymentModal({ row, key, type }) {
            if (type === 'delete') {
                this.deleteVisible = true
                this.dataForModal = { row, key, type }
                return
            }
            else if(type ==='payment'){
                this.dialogVisible = true
                this.dataForModal = { row, key, type }
                return
            }
        },

        refetchData() {
            this.loading = true
            store.dispatch('admin/reporting/getDebtorsList')
                .then((res) => { 
                    this.tableData = res
                    this.$notify({
                        title: 'Success',
                        message: 'Debotor cleared',
                        type: 'success'
                    });
                 }) 
                .finally(() => this.loading = false)
        },
        openNewLink({row, key}) {
            store.dispatch('admin/reporting/getSpecificDebtor', row.payment_id).then((data) => {
                this.$router.push(`/admin/finance/debtors_list/${row.payment_id}`)
            })
        },
    },

    computed: {
        searchingTableData() {
            if (!this.search) {
                this.total = this.tableData.length;
                return this.tableData;
            }
            this.page = 1;
            return this.tableData.filter(data => data.user.firstname.toLowerCase().includes(this.search.toLowerCase())
                || data.user.lastname.toLowerCase().includes(this.search.toLowerCase()));
        },
        displayData() {
            this.total = this.searchingTableData.length;
            return this.searchingTableData.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
        },
    },
    mounted() {
        this.loading = true
        store.dispatch('admin/reporting/getDebtorsList')
            .then(res => this.tableData = res)
            .finally(() => this.loading = false)
    },

}

</script>

<style scoped>
.view-payment, .delete-icon, .make-payment{
    background-color: transparent;
    border-color: transparent;
}

.view-payment:hover svg path{
    fill: #f7921c;
    transition: .5s;
}

.delete-icon:hover svg path{
    fill: red;
    transition: .5s;
}

.buttons-container{
    display: flex;
    justify-content: space-evenly;
}

.due {
    color: #EE6463;
    background: rgba(54, 217, 98, 0.1);
}
.completed {
    color: #36D962;
    background: rgba(54, 217, 98, 0.1);
}
</style>
