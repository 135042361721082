<template>
    <svg height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 502.672 502.672" xml:space="preserve">
        <g>
            <g>
                <g>
                    <path style="fill:#010002;" d="M106.905,80.468c-4.702,5.263-6.989,11.497-6.989,18.723c0,7.118,1.963,13.05,5.932,17.861
				c4.012,4.81,10.85,8.434,20.277,10.85V70.675C117.971,71.947,111.586,75.204,106.905,80.468z" />
                    <path style="fill:#010002;"
                        d="M138.614,152.816v62.642c8.132-1.014,14.884-4.53,20.19-10.591
				c5.263-6.04,8.024-13.525,8.024-22.434c0-7.636-1.941-13.719-5.695-18.357C157.402,159.503,149.917,155.707,138.614,152.816z" />
                    <path style="fill:#010002;" d="M479.583,291.775l0.841,1.294l-19.176-65.489c-2.438-8.348-8.003-15.229-15.66-19.414
				c-7.636-4.163-16.458-5.134-24.742-2.696l-3.969,1.165c-6.536,1.898-11.066,6.967-15.164,12.209
				c-5.846-16.049-22.779-25.41-39.367-20.579l-9.232,2.718c-6.04,1.79-10.656,5.803-14.647,10.246
				c-0.043-0.151-0.561-2.049-0.561-2.049c-5.048-17.235-23.232-27.114-40.445-22.045l-9.232,2.696
				c-5.716,1.661-9.362,6.428-13.244,10.721c-4.034-13.676-8.003-27.33-11.368-38.784V57.581c0-28.668-23.447-52.072-52.029-52.072
				H52.093C23.469,5.509,0,28.914,0,57.581v182.23c0,28.668,23.469,52.093,52.093,52.093h159.494c4.077,0,8.003-0.582,11.821-1.488
				c2.934,10.074,4.94,16.911,5.242,17.968c-4.918,15.121-13.956,62.361-16.933,77.935c0.043,5.134,2.265,25.152,17.58,35.851
				l92.905,59.622c2.243,1.38,3.387,2.157,3.387,2.157c22.024,12.511,42.991,16.178,61.261,10.829l54.811-16.049
				c37.08-10.829,61.52-56.106,61.002-91.503C502.297,366.604,487.672,307.91,479.583,291.775z M336.785,465.808
				c-1.877-1.057-3.689-2.2-3.689-2.2c0.367,0.28-91.697-58.737-91.697-58.737c-6.191-4.336-8.456-14.15-8.758-17.58
				c6.191-30.954,14.172-69.199,16.588-74.635l1.553-3.516l-0.992-3.947l-45.881-156.518c-1.726-5.954,1.812-12.446,7.765-14.215
				l9.232-2.653c6.04-1.79,12.403,1.704,14.172,7.744l46.291,158.114l23.814-8.391l-2.589-9.707l-16.2-55.264
				c-0.841-2.89-0.475-5.954,0.949-8.65c1.51-2.696,3.926-4.659,6.816-5.522l9.232-2.696c6.083-1.79,12.425,1.704,14.194,7.765
				l19.759,67.344l26.51-4.724l-3.408-11.238l-9.168-31.04c-1.79-6.018,1.747-12.403,7.766-14.194l9.254-2.718
				c6.018-1.769,12.382,1.747,14.15,7.787l13.719,46.658l27.244-3.085l-3.408-12.08l-4.918-16.782
				c-0.82-2.934-0.475-6.018,0.992-8.715c1.402-2.675,3.861-4.616,6.73-5.436l4.055-1.186c2.891-0.863,5.91-0.496,8.585,0.949
				c2.696,1.467,4.681,3.883,5.522,6.795l19.306,65.856l0.216,0.496c2.416,6.514,20.579,61.757,21.075,87.621
				c0.388,27.05-18.357,62.836-45.752,70.882l-54.854,16.049C365.021,479.117,348.109,472.344,336.785,465.808z M174.443,217.464
				c-9.232,9.728-21.139,14.905-35.872,15.617v21.312h-12.425v-0.022v-21.01c-10.397-1.294-18.961-3.624-25.583-7.054
				c-6.536-3.365-12.166-8.844-16.911-16.394c-4.832-7.571-7.571-16.782-8.283-27.697l21.075-4.055
				c1.704,11.303,4.487,19.565,8.564,24.85c5.867,7.485,12.964,11.648,21.118,12.468v-65.834
				c-8.542-2.243-17.321-5.846-26.36-10.915c-6.709-3.732-11.842-8.866-15.445-15.488c-3.645-6.601-5.436-14.064-5.436-22.455
				c0-14.905,5.263-26.963,15.811-36.217c7.032-6.234,17.537-10.009,31.407-11.433V43.042h12.446v10.095
				c12.144,1.165,21.851,4.767,28.926,10.807c9.189,7.636,14.625,18.184,16.502,31.493l-21.571,3.257
				c-1.294-8.262-3.84-14.56-7.744-18.982c-3.904-4.4-9.254-7.291-16.07-8.693v60.161c10.591,2.696,17.515,4.81,21.01,6.277
				c6.493,2.869,11.799,6.385,15.962,10.505c4.12,4.098,7.291,9.017,9.448,14.69c2.286,5.673,3.343,11.821,3.343,18.443
				C188.356,195.634,183.761,207.735,174.443,217.464z" />
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </g>
    </svg>
</template>
<script>
export default {

}
</script>
<style scoped>
svg {
    width: 45%;
    height: 100%;
    position: relative;
    top: 3px;
}

path {
    fill: #d4d8e2 !important;
}

svg:hover path {
    fill: rgb(9, 167, 164) !important;
    transition: 0.5s;
}
</style>